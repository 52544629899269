import { pnApi } from '../pnBaseApi';
import { CropAdvisor, ResetCropAdvisorPassword, UpdateCropAdvisor } from '../types/advisors.types';
import {
	RestApiResponse,
	RestApiResponseWithData,
	RestApiResponseWithPagination,
} from '../types/apiResponse.types';
import {
	GetAllUsersQueryParams,
	GetCropAdvisorQueryParams,
} from '../types/queryParams.types';
import {
	CreateUserRequestBody,
	InviteCropAdvisor,
	InviteUserRequestBody,
	ResendInviteCropAdvisor,
	ResetPasswordRequestBody,
	ToggleUserBody,
	UpdateUserRequestBody,
	User,
	VerifyUserEmailBody,
} from '../types/user.types';

const userApi = pnApi.injectEndpoints({
	endpoints: (builder) => ({
		getAllUsers: builder.query<
			RestApiResponseWithPagination<User[]>,
			GetAllUsersQueryParams
		>({
			query: (args) => {
				const { search, ...restOfArgs } = args;
				return {
					url: 'User',
					params: search !== '' && search !== undefined ? args : restOfArgs,
				};
			},
			providesTags: ['TMSUsers'],
		}),

		inviteUser: builder.mutation<
			RestApiResponseWithData<User>,
			InviteUserRequestBody
		>({
			query(body) {
				return {
					url: 'User/InviteUser',
					method: 'Post',
					body,
				};
			},
			invalidatesTags: ['User', 'TMSUsers'],
		}),

		createUser: builder.mutation<
			RestApiResponseWithData<User>,
			CreateUserRequestBody
		>({
			query(body) {
				return {
					url: 'User/CreateUser',
					method: 'Post',
					body,
				};
			},
			invalidatesTags: ['User', 'TMSUsers'],
		}),

		toggleUser: builder.mutation<RestApiResponseWithData<User>, ToggleUserBody>(
			{
				query(body) {
					return {
						url: 'User/Enabled',
						method: 'PUT',
						body,
					};
				},
				invalidatesTags: (result) => [
					{ type: 'User', id: result?.data?.id },
					'TMSUsers',
				],
			}
		),

		verifyUserEmail: builder.mutation<
			RestApiResponseWithData<User>,
			VerifyUserEmailBody
		>({
			query(body) {
				return {
					url: 'User/EmailConfirmed',
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'User', id: result?.data?.id },
				'TMSUsers',
			],
		}),

		sendPasswordReset: builder.mutation<
			RestApiResponseWithData<string>,
			ResetPasswordRequestBody
		>({
			query(body) {
				return {
					url: 'User/ResetPassword',
					method: 'POST',
					body,
				};
			},
		}),

		getSingleUser: builder.query<RestApiResponseWithData<User>, string>({
			query: (id) => {
				return {
					url: `User/${id}`,
				};
			},
			providesTags: (result) =>
				result ? [{ type: 'User', id: result.data?.id }, 'User'] : ['User'],
		}),

		updateUser: builder.mutation<
			RestApiResponseWithData<User>,
			UpdateUserRequestBody
		>({
			query(body) {
				return {
					url: 'User',
					method: 'PUT',
					body,
				};
			},
			invalidatesTags: (result) => [
				{ type: 'User', id: result?.data?.id },
				'TMSUsers',
			],
		}),

		getCurrentUser: builder.query<RestApiResponseWithData<User>, string>({
			query(token) {
				return {
					url: 'User/CurrentUser',
					method: 'Get',
					headers: { Authorization: `Bearer ${token}` },
				};
			},
		}),

		//CA User management
		getCropAdvisorCandidates: builder.query<
			RestApiResponseWithPagination<CropAdvisor[]>,
			GetCropAdvisorQueryParams
		>({
			query: (params) => {
				return {
					url: 'User/CropAdvisor',
					params,
				};
			},
			providesTags: ['CAUsers'],
		}),

		inviteCropAdvisor: builder.mutation<RestApiResponse, InviteCropAdvisor>({
			query(body) {
				return {
					url: 'User/CropAdvisor/Invite',
					method: 'Post',
					body,
				};
			},
			invalidatesTags: ['CAUsers'],
		}),

		resendInviteCropAdvisor: builder.mutation<
			RestApiResponse,
			ResendInviteCropAdvisor
		>({
			query(body) {
				return {
					url: 'User/CropAdvisor/ResendInvite',
					method: 'Post',
					body,
				};
			},
			invalidatesTags: ['CAUsers'],
		}),

		updateCropAdvisor: builder.mutation<RestApiResponse, UpdateCropAdvisor>({
			query: (body) => {
				return {
					url: `User/CropAdvisor/${body.id}`,
					method: 'PATCH',
					body,
				};
			},
			invalidatesTags: ['CAUsers'],
		}),

		resetCropAdvisorPassword: builder.mutation<RestApiResponse, ResetCropAdvisorPassword>({
			query: (body) => {
				return {
					url: 'User/ResetPassword',
					method: 'POST',
					body
				}
			}
		})
	}),
});

export const {
	useGetAllUsersQuery,
	useInviteUserMutation,
	useToggleUserMutation,
	useVerifyUserEmailMutation,
	useSendPasswordResetMutation,
	useGetSingleUserQuery,
	useLazyGetSingleUserQuery,
	useUpdateUserMutation,
	useCreateUserMutation,
	useGetCropAdvisorCandidatesQuery,
	useInviteCropAdvisorMutation,
	useResendInviteCropAdvisorMutation,
	useUpdateCropAdvisorMutation,
	useResetCropAdvisorPasswordMutation
} = userApi;

export { userApi };
